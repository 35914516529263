/* eslint-disable consistent-return */
import { XmltojsonApi } from '@xpanseinc/transformers-service-api';
import {
  AuthorizationServiceApi,
  Configuration as UserApiConfiguration,
} from '@xpanseinc/authorization-service-api-rest';
import { UiBulkEventProcessorControllerApi } from '@xpanseinc/event-processor-api';
import {
  InvoicingServiceApi,
  Configuration as InvoicingApiConfiguration,
} from '@xpanseinc/invoicing-api';
import { TaxControllerApi, Configuration as TaxApiConfiguration } from '@xpanseinc/tax-service-api';
import {
  PaymentServiceApi,
  Configuration as PaymentApiConfiguration,
} from '@xpanseinc/payment-service-api';
import {
  ReportApi,
  ReportSLAApi,
  SavedSearchApi,
  SearchReportApi,
  MilestoneApi,
  WorkflowVersionApi,
} from '@xpanseinc/report-service-api';
import {
  AttachmentApi,
  Configuration,
  EventApi,
  FeesApi,
  GeoApi,
  OrderApi,
  OrderDetailApi,
  SearchApi,
  UserProfileControllerApi,
  VendorApi,
} from '@xpanseinc/ui-backend-api';
import { derived, writable } from 'svelte/store';
import fetchIntercept from 'fetch-intercept';
import { auth, lastActivityTime } from './auth';
import {
  FULFILLMENT_SERVICE_URL,
  INVOICE_SERVICE_URL,
  REPORT_SERVICE_URL,
  TAX_SERVICE_URL,
  TRANSFORMER_SERVICE_URL,
  USER_SERVICE_URL,
  PAYMENT_SERVICE_URL,
  EVENT_PROCESSOR_URL,
} from '../const';

const apiConfiguration = derived([auth], ([$auth]) => {
  if (FULFILLMENT_SERVICE_URL && $auth?.authToken) {
    return new Configuration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: FULFILLMENT_SERVICE_URL,
    });
  }
});

const eventProcessorApiConfiguration = derived([auth], ([$auth]) => {
  if (EVENT_PROCESSOR_URL && $auth?.authToken) {
    return new Configuration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: EVENT_PROCESSOR_URL,
    });
  }
});

const transformersApiConfiguration = derived([auth], ([$auth]) => {
  if (TRANSFORMER_SERVICE_URL && $auth?.authToken) {
    return new Configuration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: TRANSFORMER_SERVICE_URL,
    });
  }
});

const reportApiConfiguration = derived([auth], ([$auth]) => {
  if (REPORT_SERVICE_URL && $auth?.authToken) {
    return new Configuration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: REPORT_SERVICE_URL,
    });
  }
});

const userApiConfiguration = derived([auth], ([$auth]) => {
  if (USER_SERVICE_URL && $auth?.authToken) {
    return new UserApiConfiguration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: USER_SERVICE_URL,
    });
  }
});

const taxApiConfiguration = derived([auth], ([$auth]) => {
  if (TAX_SERVICE_URL && $auth?.authToken) {
    return new TaxApiConfiguration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: TAX_SERVICE_URL,
    });
  }
});

const paymentApiConfiguration = derived([auth], ([$auth]) => {
  if (PAYMENT_SERVICE_URL && $auth?.authToken) {
    return new PaymentApiConfiguration({
      accessToken: `Bearer ${$auth.authToken}`,
      basePath: PAYMENT_SERVICE_URL,
    });
  }
});

export const invoicingConfigHeaders = writable({});
const invoicingApiConfiguration = derived(
  [auth, invoicingConfigHeaders],
  ([$auth, $invoicingConfigHeaders]) => {
    if (INVOICE_SERVICE_URL && $auth?.authToken) {
      return new InvoicingApiConfiguration({
        accessToken: `Bearer ${$auth.authToken}`,
        basePath: INVOICE_SERVICE_URL,
        headers: $invoicingConfigHeaders,
      });
    }
  },
);

export const searchApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new SearchApi($apiConfiguration),
);

export const milestoneApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) => $reportApiConfiguration && new MilestoneApi($reportApiConfiguration),
);

export const eventApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new EventApi($apiConfiguration),
);

export const attachmentApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new AttachmentApi($apiConfiguration),
);

export const geoApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new GeoApi($apiConfiguration),
);

export const feesApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new FeesApi($apiConfiguration),
);

export const orderApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new OrderApi($apiConfiguration),
);

export const orderDetailApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new OrderDetailApi($apiConfiguration),
);

export const userProfileApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new UserProfileControllerApi($apiConfiguration),
);

export const xmlToJsonApi = derived(
  transformersApiConfiguration,
  ($transformersApiConfiguration) =>
    $transformersApiConfiguration && new XmltojsonApi($transformersApiConfiguration),
);

export const reportApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) => $reportApiConfiguration && new ReportApi($reportApiConfiguration),
);

export const workflowVersionApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) =>
    $reportApiConfiguration && new WorkflowVersionApi($reportApiConfiguration),
);

export const reportSlaApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) => $reportApiConfiguration && new ReportSLAApi($reportApiConfiguration),
);

export const reportSearchApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) =>
    $reportApiConfiguration && new SearchReportApi($reportApiConfiguration),
);

export const saveSearchApi = derived(
  reportApiConfiguration,
  ($reportApiConfiguration) =>
    $reportApiConfiguration && new SavedSearchApi($reportApiConfiguration),
);

export const userApi = derived(
  userApiConfiguration,
  ($userApiConfiguration) =>
    $userApiConfiguration && new AuthorizationServiceApi($userApiConfiguration),
);

export const vendorApi = derived(
  apiConfiguration,
  ($apiConfiguration) => $apiConfiguration && new VendorApi($apiConfiguration),
);

export const invoicingApi = derived(
  invoicingApiConfiguration,
  ($invoicingApiConfiguration) =>
    $invoicingApiConfiguration && new InvoicingServiceApi($invoicingApiConfiguration),
);

export const bulkApi = derived(
  eventProcessorApiConfiguration,
  ($eventProcessorApiConfiguration) =>
    $eventProcessorApiConfiguration &&
    new UiBulkEventProcessorControllerApi($eventProcessorApiConfiguration),
);

export const taxApi = derived(
  taxApiConfiguration,
  ($taxApiConfiguration) => $taxApiConfiguration && new TaxControllerApi($taxApiConfiguration),
);

export const paymentApi = derived(
  paymentApiConfiguration,
  ($paymentApiConfiguration) =>
    $paymentApiConfiguration && new PaymentServiceApi($paymentApiConfiguration),
);

fetchIntercept.register({
  request(url, requestConfig) {
    lastActivityTime.set(Date.now());
    const modifiedConfig = requestConfig;
    if (modifiedConfig) {
      // modifiedConfig.headers['cid'] = Date.now(); // Data Dog production
    }
    return [url, modifiedConfig];
  },

  requestError(error) {
    // Called when an error occurred during another 'request' interceptor call
    return Promise.reject(error);
  },

  response(response) {
    // Modify the response object
    if (response.status === 401) {
      auth.subscribe(($auth) => {
        $auth.logout();
      });
    }
    return response;
  },

  responseError(error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
